@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.colors.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";

.LogoList {
	@include coloredComponent;
	padding: var(--spacing--5xl) 0;

	@include mq("md") {
		padding: var(--spacing--4xl) 0;
	}
}

.LogoList_trumpetHeading {
	@include body--lg-reg;
	padding-bottom: var(--spacing--2xl);

	@include mq("lg") {
		padding-bottom: var(--spacing--4xl);
	}
}

.LogoList_heading {
	padding-bottom: var(--spacing--2xl);
	@include mq("md") {
		max-width: 75%;
	}
}

.LogoList_logos {
	display: grid;
	gap: var(--spacing--2xl);

	@include mq("md") {
		grid-template-columns: repeat(2, 1fr);
		padding-left: 20%;
		padding-right: var(--spacing--2xl);
	}

	@include mq("lg") {
		grid-template-columns: repeat(3, 1fr);
		padding-left: 25%;
	}

	@include mq("xl") {
		grid-template-columns: repeat(4, 1fr);
	}
}

.LogoList_logo {
	padding-bottom: var(--spacing--sm);

	> * {
		@include aspect-ratio(5, 3);
	}
}
// Theme: Kogp
[data-theme='kogp'] {
	.LogoList_trumpetHeading {
		font-weight: var(--font-weight--extrablack);
	}
}